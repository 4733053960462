<template>
    <main class="Home">
        <SliceZone
            v-if="page.data.slices"
            wrapper="div"
            :slices="page.data.slices ?? []"
            :components="components"
            class="Home__slice-zone"
        />
    </main>
</template>

<script setup lang="ts">
import { components } from '~/slices'

const prismic = usePrismic()
const { data: page } = await useAsyncData('[home]', () =>
    prismic.client.getSingle('home', {
        fetchLinks: [
            'case_study.title',
            'case_study.card_title',
            'case_study.hero_image',
            'case_study.brand',
            'case_study.capability_tags',
            'capability.capability_name',
            'brand.client_name',
            'brand.client_logo',
            'new_post.title',
            'case_study.theme',
            'theme.theme_base_colour',
            'theme.theme_chip_colour',
            'theme.theme_logo',
            'theme.theme_icon',
            'theme.theme_chip_icon_colour',
        ],
    }),
)

useSeoMeta({
    title:
        page.value?.data?.meta_title ||
        'eight&four | A Performance Driven Social and Content Agency',
    description:
        page.value?.data?.meta_description ||
        "We're a social & end-to-end content production agency. We work with global brands looking for a dynamic, multi-lingual agency.",
    ogTitle:
        page.value?.data?.meta_title ||
        'eight&four | A Performance Driven Social and Content Agency',
    ogDescription:
        page.value?.data?.meta_description ||
        "We're a social & end-to-end content production agency. We work with global brands looking for a dynamic, multi-lingual agency.",
    ogImage:
        page.value?.data?.social_image?.url ||
        process.env.BASE_URL + '/images/default-og-image.png',
})

onMounted(() => {
    const { smoothScroller } = useGsap()

    smoothScroller?.effects('[data-speed]')
})
</script>

<style lang="scss" scoped>
.Home {
    @include grid;
}

.Home__slice-zone {
    @include grid;

    grid-column: 1 / span 24;
}
</style>
